<template>
  <div>
    <MyResume />

    <section class="container-fluid g-px-70--lg g-px-50--sm g-pt-10">
      <div class="row">
        <div class="col-sm-6 col-lg-6">
          <h5 class="d-flex align-items-center g-color-gray-dark-v4 mb-0">
            <p class="g-font-size-20--sm g-font-size-36--lg">
              <router-link :to="{ name: 'claims' }">
                <img src="assets/img/arrow.svg" />
              </router-link>
              Nº {{ currentClaimSelected.claim.internalFolio }}
            </p>
            <p
              class="g-font-size-20--sm g-font-size-26--lg g-color-darkgreen g-pl-50"
            >
              <span
                class="orange--text"
                v-if="
                  allConstants.dashboard.groups.claimant.claimsActive.statuses.includes(
                    currentClaimSelected.claim.state
                  )
                "
              >
                <span
                  class="blue--text"
                  v-if="currentClaimSelected.claim.state == 'INGRESADO'"
                  >{{
                    currentClaimSelected.claim.lastActionCode
                      | wfStatusForClaimant
                  }}</span
                >
                <span v-else>{{
                  currentClaimSelected.claim.lastActionCode
                    | wfStatusForClaimant
                }}</span>
              </span>
              <span
                class="green--text"
                v-if="
                  allConstants.dashboard.groups.claimant.claimsSolved.statuses.includes(
                    currentClaimSelected.claim.state
                  )
                "
              >
                {{
                  currentClaimSelected.claim.lastActionCode
                    | wfStatusForClaimant
                }}
              </span>
            </p>
          </h5>
        </div>
        <div class="col-sm-6 col-lg-6">
          <h4 class="d-flex align-items-center g-color-gray-dark-v4 mb-0">
            <router-link
              to="#"
              v-on:click.native="viewHistory()"
              class="btn btn-md u-btn-outline-darkgray g-mr-10 g-mb-15"
            >
              Ver historial de la solicitud
            </router-link>
            <router-link
              :to="{ name: 'claims' }"
              class="btn btn-md u-btn-orange g-mb-15"
            >
              Volver a todos los reclamos
            </router-link>
          </h4>
        </div>
      </div>
      <hr class="g-my-10" style="border: 1px solid;" />
    </section>

    <Resolution />

    <section class="container-fluid g-px-80--lg g-px-50--sm g-pt-40">
      <div class="row">
        <div class="col-sm-3 col-lg-3">
          <h6 class=" g-pt-10">
            <img src="assets/img/bank.svg" /> Banco o Institución
          </h6>
          <p>{{ getBankName(currentClaimSelected.claim.bank.code) }}</p>
        </div>
        <div class="col-sm-3 col-lg-3">
          <p class="h6 g-pt-10"><img src="assets/img/home.svg" /> Sucursal</p>
          <p>{{ currentClaimSelected.claim.bank.branch }}</p>
        </div>
        <div class="col-sm-2 col-lg-2">
          <p class="h6 g-pt-10"><img src="assets/img/map.svg" /> Región</p>
          <p>{{ getRegionName(currentClaimSelected.claim.bank.region) }}</p>
        </div>
        <div class="col-sm-2 col-lg-2">
          <p class="h6 g-pt-10"><img src="assets/img/map.svg" /> Comuna</p>
          <p>
            {{
              getCommuneName(
                currentClaimSelected.claim.bank.region,
                null,
                currentClaimSelected.claim.bank.commune
              )
            }}
          </p>
        </div>
      </div>
      <div class="row g-mt-20">
        <div class="col-sm-3 col-lg-3">
          <h6 class=" g-pt-10">
            <img src="assets/img/name.svg" /> Nombre Ejecutivo
          </h6>
          <p>{{ currentClaimSelected.claim.bank.executiveName }}</p>
        </div>
        <div class="col-sm-3 col-lg-3">
          <p class="h6 g-pt-10">
            <img src="assets/img/mail.svg" /> Email ejecutivo
          </p>
          <p>{{ currentClaimSelected.claim.bank.executiveEmail }}</p>
        </div>
        <div class="col-sm-2 col-lg-2">
          <p class="h6 g-pt-10">
            <img src="assets/img/telephone.svg" /> Teléfono
          </p>
          <p>+56 {{ currentClaimSelected.claim.bank.executivePhone }}</p>
        </div>
      </div>

      <div class="row g-mt-20">
        <div class="col-sm-3 col-lg-3">
          <h6 class=" g-pt-10">
            <img src="assets/img/product.svg" /> Producto
          </h6>
          <p>
            <span
              v-for="productCode in currentClaimSelected.claim.detail
                .productCodes"
              :key="productCode"
            >
              {{ getProductName(productCode) }}
            </span>
          </p>
        </div>
        <div class="col-sm-3 col-lg-3">
          <p class="h6 g-pt-10">
            <img src="assets/img/money.svg" /> Monto Reclamado
          </p>
          <p>
            {{ currentClaimSelected.claim.detail.amount | formatClassicNumber }}
          </p>
        </div>
        <div class="col-sm-2 col-lg-2">
          <p class="h6 g-pt-10">
            <img src="assets/img/wallet.svg" /> Tipo de divisa
          </p>
          <p>
            {{
              getCurrencyName(currentClaimSelected.claim.detail.currencyType)
            }}
          </p>
        </div>
      </div>
      <hr class="g-my-10" style="border: 1px solid;" />
    </section>

    <section
      class="container-fluid g-px-80--lg g-px-50--sm g-pt-40 text-justify"
    >
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <div class="h6">
            <img src="assets/img/msg-exc.svg" /> Descripción de los hechos
          </div>
          <p>{{ currentClaimSelected.claim.detail.reason }}</p>
        </div>

        <div class="col-sm-12 col-lg-12">
          <div class="h6">
            <img src="assets/img/msg-exc.svg" /> Petición concreta
          </div>
          <p>{{ currentClaimSelected.claim.detail.request }}</p>
        </div>
      </div>
    </section>

    <section class="container-fluid g-px-80--lg g-px-50--sm g-pt-40">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <v-row>
            <v-col cols="12" md="6">
              <h5 class="g-font-weight-700">Documentos del cliente</h5>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-right"
              v-if="currentClaimSelected.claim.lastActionCode == ''"
            >
              <DocumentsAdd @fileUploadFinish="gotoView('claims')" />
            </v-col>
          </v-row>
          <div class="table-responsive">
            <v-form v-model="form.isValid" @submit.prevent="enter">
              <table class="table table-striped">
                <thead>
                  <tr class="g-bg-cyan g-color-white">
                    <th>Nombre documento</th>
                    <th>Documento</th>
                    <th>Fecha</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="document in documentsUploadedByClaimant"
                    :key="document.id"
                  >
                    <td>
                      <v-progress-linear
                        buffer-value="0"
                        stream
                        color="cyan"
                        v-if="form.processing"
                      ></v-progress-linear>
                      <router-link
                        to="#"
                        v-on:click.native="downloadDocument(document)"
                        v-else
                      >
                        {{ document.fileName }}
                        <img src="assets/img/cloud.svg" />
                      </router-link>
                    </td>
                    <td
                      :class="{
                        'red--text':
                          document.state ==
                            allConstants.claims.documentStatuses
                              .rejectedFirstInstance ||
                          document.state ==
                            allConstants.claims.documentStatuses
                              .rejectedSecondInstance,
                      }"
                    >
                      {{ document.name }}
                    </td>
                    <td>{{ document.creationDate | formatDateShort }}</td>
                    <td
                      :class="{
                        'red--text':
                          document.state ==
                            allConstants.claims.documentStatuses
                              .rejectedFirstInstance ||
                          document.state ==
                            allConstants.claims.documentStatuses
                              .rejectedSecondInstance,
                      }"
                    >
                      {{ getDocumentStatusName(document.state) }}
                    </td>
                    <td>
                      <v-btn
                        text
                        class="text-capitalize"
                        color="blue"
                        @click="beginReplaceDocument(document.id)"
                        v-if="canReplaceTheDocument(document)"
                        dark
                      >
                        <v-icon class="mr-2">mdi-cloud-upload</v-icon>Subir
                      </v-btn>
                    </td>
                  </tr>

                  <tr
                    v-for="documento in documentosRequeridos"
                    :key="documento.name"
                  >
                    <td>
                      <v-file-input
                        v-model="documento.blob"
                        :accept="documento.allowedFileTypes.join(',')"
                        truncate-length="20"
                        :clearable="true"
                        :rules="
                          documento.required
                            ? formFields.documentosRequeridosRules
                            : formFields.documentosAlternativosRules
                        "
                        :disabled="form.processing"
                        @change="setLastSelectedDocument(documento)"
                      >
                      </v-file-input>
                    </td>
                    <td>
                      {{ documento.name }} {{ documento.required ? "(*)" : "" }}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr v-if="showActions">
                    <td colspan="5" class="text-center">
                      <v-btn
                        type="submit"
                        color="green"
                        class="mr-3 text-white"
                        :disabled="!form.isValid || page.loading"
                        :loading="form.processing"
                        ><v-icon>mdi-content-save-outline</v-icon> Enviar
                        documentos</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-form>
          </div>
        </div>
      </div>
    </section>

    <OtherDocuments
      :parentIsProcessing="form.processing"
      @executeAction="executeAction"
    />

    <v-dialog
      v-model="historyToView.show"
      width="900px"
      @click:outside="historyToView.show = false"
    >
      <v-card>
        <div class="pa-6">
          <p class="h4">
            <img src="assets/img/arrow.svg" /> Bitácora Reclamo Nº
            {{ currentClaimSelected.claim.internalFolio }}
          </p>
          <v-progress-linear
            indeterminate
            color="cyan"
            class="mt-5"
            v-if="historyToView.loading"
          ></v-progress-linear>
          <div class="table-responsive" v-else>
            <table class="table table-striped">
              <thead>
                <tr class="g-bg-gray-light-v1 g-color-white">
                  <th>Estado</th>
                  <th>Fecha</th>
                  <th>Rol</th>
                  <th>Nombre</th>
                  <th>Comentarios</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="event in historyToView.events" :key="event.id">
                  <td>{{ event.actionCode | wfStatusForClaimant }}</td>
                  <td>
                    {{ event.creationDate | formatDateShort }}
                    {{ event.creationDate | formatTimeShort }}
                  </td>
                  <td>{{ event.participant.roleName }}</td>
                  <td>
                    <div v-if="event.participant.roleId == 4">Banco</div>
                    <div v-else-if="event.participant.roleId == 5">Banco</div>
                    <div v-else>{{ event.participant.name }}</div>
                  </td>
                  <td>{{ event.comments }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <DocumentChanger
      :idDocumentToReplace="idDocumentToReplace"
      @enter="endReplaceDocument"
      @abort="idDocumentToReplace = ''"
    />
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

import MyResume from "@/components/PublicSite/User/MyResume";
import Resolution from "@/components/PublicSite/User/Claim/Resolution";
import DocumentChanger from "@/components/PublicSite/User/Claim/DocumentChanger";
import OtherDocuments from "@/components/PublicSite/User/Claim/OtherDocuments";

import DocumentsAdd from "@/components/PublicSite/User/Claim/DocumentAdd";

import claimsService from "@/services/claims";
import attachmentsService from "@/services/attachments";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
  components: {
    MyResume,
    Resolution,
    DocumentChanger,
    OtherDocuments,
    DocumentsAdd,
  },
  data() {
    return {
      showActions: false,
      historyToView: {
        show: false,
        loading: false,
        events: [],
      },
      idDocumentToReplace: "",
      posibleActions: [],
      documentosRequeridos: [],
      lastSelectedDocument: null,
      formFields: {
        documentosRequeridosRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) => this.isValidFileType(value) || "Formato incorrecto",
          (value) =>
            this.isValidFileSize(value) ||
            `Tamaño máximo ${this.calculateMegaBytes()} Mb`,
        ],
        documentosAlternativosRules: [
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) => this.isValidFileType(value) || "Formato incorrecto",
          (value) =>
            this.isValidFileSize(value) ||
            `Tamaño máximo ${this.calculateMegaBytes()} Mb`,
        ],
      },
    };
  },
  computed: {
    documentsUploadedByClaimant() {
      const idsDocumentsUploadedInCreation = this.currentClaimSelected.claim.events.find(
        (event) => event.actionCode == ""
      ).documentIds;
      //const idsDocumentsUploadedInOther = this.currentClaimSelected.claim.events.filter(event => event.actionCode == "NEW-UPLOAD-FILE" && event.rol == "Claimant");
      const idsDocumentsUploadedInCreationRespuesta = this.currentClaimSelected.claim.events.find(
        (event) =>
          event.actionCode == "RESPUESTA-ANTECEDENTES-ADICIONALES-CLIENTE"
      );

      let result = [];

      this.currentClaimSelected.claim.documents
        .filter((document) =>
          idsDocumentsUploadedInCreation.includes(document.id)
        )
        .forEach((doc) => {
          result.push(doc);
        });

      this.currentClaimSelected.claim.documents
        .filter((document) => document.name == "Documento adicional")
        .forEach((doc) => {
          result.push(doc);
        });

      /*
                this.currentClaimSelected.claim.documents.forEach(
                    (doc) => {
                        idsDocumentsUploadedInOther.forEach(
                            (docUp) => {
                                if (docUp.documentIds.includes(doc.id))
                                    result.push(doc);
                            }
                        );
                        
                    }
                );
                */

      if (idsDocumentsUploadedInCreationRespuesta != undefined) {
        this.currentClaimSelected.claim.documents
          .filter((document) =>
            idsDocumentsUploadedInCreationRespuesta.documentIds.includes(
              document.id
            )
          )
          .forEach((doc) => {
            result.push(doc);
          });
      }

      return result;
    },
  },
  methods: {
    beginReplaceDocument(documentId) {
      this.idDocumentToReplace = documentId;
    },
    async endReplaceDocument() {
      this.idDocumentToReplace = "";

      this.initSignalProcessing();

      await this.reloadClaim();
      await this.regularizeIfIsPossible();

      this.stopSignalProcessing();
    },
    async downloadDocument(theDocument) {
      this.initSignalProcessing();

      try {
        const downlodResult = await attachmentsService.downlod(
          theDocument.blobId,
          "claims"
        );

        const urlCreator = window.URL || window.webkitURL;
        const contentBlob = new Blob([downlodResult.data], {
          type: theDocument.contentType,
        });

        let link = document.createElement("a");
        link.href = urlCreator.createObjectURL(contentBlob);
        link.download = theDocument.fileName;
        link.click();
      } catch {
        this.showInfoNotification(
          "El archivo ya no existe en nuestros registros"
        );
      }

      this.stopSignalProcessing();
    },
    async viewHistory() {
      this.historyToView.loading = true;
      this.historyToView.show = true;
      this.historyToView.events = [];

      this.historyToView.events = await this.loadClaimHistory();
      this.historyToView.loading = false;
    },
    canReplaceTheDocument(theDocument) {
      if (
        this.allConstants.dashboard.groups.claimant.claimsSolved.statuses.includes(
          this.currentClaimSelected.claim.state
        )
      )
        return false;

      return (
        theDocument.state ==
          this.allConstants.claims.documentStatuses.rejectedFirstInstance ||
        document.state ==
          this.allConstants.claims.documentStatuses.rejectedSecondInstance
      );
    },
    async executeAction(actionCode, relatedDocumentId) {
      this.initSignalProcessing();

      await this.changeWfStatusFromAction(actionCode);

      if (relatedDocumentId) {
        if (
          actionCode ==
          this.allConstants.claims.currentEventActions.fastSolution
            .claimantAccept
        ) {
          await claimsService.changeDocumentStatus(
            this.currentClaimSelected.claim.id,
            relatedDocumentId,
            this.allConstants.claims.documentStatuses.approvedByClaimant,
            {}
          );
        }
        if (
          actionCode ==
          this.allConstants.claims.currentEventActions.fastSolution
            .claimantReject
        ) {
          await claimsService.changeDocumentStatus(
            this.currentClaimSelected.claim.id,
            relatedDocumentId,
            this.allConstants.claims.documentStatuses.rejectedByClaimant,
            {}
          );
        }
      }

      this.gotoView("claims");
    },
    async executeActionState(actionCode, reasonCode, comments, documents) {
      this.initSignalProcessing();

      const documentsUploaded = [];

      if (documents && documents.length > 0) {
        await this.uploadDocuments(documents);

        documents.forEach((document) => {
          documentsUploaded.push({
            blobId: document.blobId,
            name: document.name,
            fileName: document.realName,
            contentType: document.fileContentType,
            contentLenght: document.fileSize.toString(),
          });
        });
      }

      await this.changeWfStatusFromAction(
        actionCode,
        reasonCode,
        comments,
        documentsUploaded,
        "00000000-0000-0000-0000-000000000000",
        "00000000-0000-0000-0000-000000000000"
      );
      this.gotoView("claims");
    },
    preparePosibleDocuments(selectedAction) {
      this.documentosRequeridos = [];

      if (!selectedAction) return;

      this.documentosRequeridos = selectedAction.attachments;
      this.documentosRequeridos.forEach((documento) => {
        documento.blob = null;
      });
    },
    setLastSelectedDocument(theDocument) {
      this.lastSelectedDocument = theDocument;
    },
    isValidFileName(fileInfo) {
      if (fileInfo == null) return true;

      return this.allConstants.regex.regexFileNameComplex.test(fileInfo.name);
    },
    isValidFileSize(fileInfo) {
      if (fileInfo == null) return true;

      return fileInfo.size <= this.lastSelectedDocument.maxFileSize;
    },
    isValidFileType(fileInfo) {
      if (fileInfo == null) return true;

      return (
        this.lastSelectedDocument.allowedFileTypes.filter(
          (fileType) => fileType == fileInfo.type
        ).length > 0
      );
    },
    enter() {
      this.executeActionState(
        this.posibleActions[0].action,
        "",
        "",
        this.documentosRequeridos.filter(
          (documento) => documento.blob && documento.blob != null
        ),
        "00000000-0000-0000-0000-000000000000",
        "00000000-0000-0000-0000-000000000000"
      );
    },
    async uploadDocuments(documents) {
      const uploadFileColecction = [];

      documents.forEach((document) => {
        if (document.blob != null) {
          document.realName = document.blob.name;
          document.fileSize = document.blob.size;
          document.fileContentType = document.blob.type;
          document.internalId = this.$uuid.v4();

          uploadFileColecction.push(
            this.uploadOneDocument(document.internalId, document.blob)
          );

          document.loading = true;
        }
      });

      const uploadFilesResult = await Promise.all(uploadFileColecction);

      uploadFilesResult.forEach((uploadResult) => {
        documents.forEach((document) => {
          if (document.internalId == uploadResult.internalId) {
            document.blobId = uploadResult.blobId;
            document.loading = false;
            document.loaded = true;
          }
        });
      });
    },
    async uploadOneDocument(internalId, blob) {
      const addResult = await attachmentsService.add(blob);

      return {
        internalId: internalId,
        blobId: addResult.data.blobId,
      };
    },
  },
  async beforeMount() {
    window.scrollTo(0, 0);
    if (
      this.currentClaimSelected.currentEvent == null ||
      this.currentClaimSelected.currentEvent == undefined ||
      this.currentClaimSelected.currentEvent.stageCode == undefined ||
      this.currentClaimSelected.currentEvent.stageCode == null
    )
      return;

    const currentStageCode = this.currentClaimSelected.currentEvent.stageCode;
    const currentStatusCode = this.currentClaimSelected.currentEvent.stateCode;

    const wfStagesConfiguration = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStages"
    ).values;
    const stageConfiguration = wfStagesConfiguration.find(
      (stage) =>
        stage.code == currentStageCode &&
        stage.stateCodes.includes(currentStatusCode)
    );

    if (
      stageConfiguration.rol !== this.allConstants.security.userRoles.claimant
    ) {
      //el usuario actual no puede efectuar ninguna acción
      return;
    }

    if (stageConfiguration.code != "ANTECEDENTES-ADICIONALES") {
      //solo se habilita esta funcionalidad para antecedentes adicionales
      return;
    }

    this.showActions = true;

    const wfStatusesConfiguration = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStatuses"
    ).values;
    const statusConfiguration = wfStatusesConfiguration.find(
      (status) => status.code == currentStatusCode
    );

    this.posibleActions = statusConfiguration.actions.filter(
      (action) => action.manual
    );
    this.posibleActions.forEach((action) => {
      action.attachments.forEach((attachment) => {
        attachment.blob = null;
      });
    });

    this.preparePosibleDocuments(this.posibleActions[0]);
  },
};
</script>

<style lang="scss" scoped></style>
