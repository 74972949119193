import { render, staticRenderFns } from "./OtherDocuments.vue?vue&type=template&id=05f0eb55&scoped=true&"
import script from "./OtherDocuments.vue?vue&type=script&lang=js&"
export * from "./OtherDocuments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f0eb55",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VBtn,VCard,VDialog,VProgressLinear})
