var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"container-fluid g-px-80--lg g-px-50--sm g-pt-40"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-12 g-mt-30 g-mb-60"},[_c('h5',{staticClass:"g-font-weight-700"},[_vm._v("Documentos incorporados")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped"},[_vm._m(0),_c('tbody',_vm._l((_vm.documents),function(document){return _c('tr',{key:document.id},[_c('td',[(_vm.parentIsProcessing)?_c('v-progress-linear',{attrs:{"buffer-value":"0","stream":"","color":"cyan"}}):_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.downloadDocument(document)}}},[_vm._v(" "+_vm._s(document.fileName)+" "),_c('img',{attrs:{"src":"assets/img/cloud.svg"}})])],1),_c('td',[_vm._v(_vm._s(document.name))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDateShort")(document.creationDate)))]),_c('td'),_c('td',[(
                      _vm.currentClaimSelected.currentEvent &&
                        _vm.currentClaimSelected.currentEvent.stateCode ==
                          'TRAMITACION-SIMPLIFICADA-ACEPTADA' &&
                        document.name == 'Propuesta Banco'
                    )?_c('div',[_c('v-btn',{staticClass:"text-capitalize white--text mr-2",attrs:{"color":"blue","small":"","loading":_vm.parentIsProcessing},on:{"click":function($event){return _vm.executeAction(
                          _vm.allConstants.claims.currentEventActions.fastSolution
                            .claimantAccept,
                          document.id
                        )}}},[_vm._v("Aceptar")]),_c('v-btn',{staticClass:"text-capitalize white--text",attrs:{"color":"blue","small":"","loading":_vm.parentIsProcessing},on:{"click":function($event){return _vm.executeAction(
                          _vm.allConstants.claims.currentEventActions.fastSolution
                            .claimantReject,
                          document.id
                        )}}},[_vm._v("Rechazar")])],1):_vm._e(),(
                      _vm.currentClaimSelected.currentEvent &&
                        _vm.currentClaimSelected.currentEvent.stateCode ==
                          'AVENIMIENTO-PENDIENTE-CLIENTE' &&
                        document.name == 'Avenimiento'
                    )?_c('div',[_c('v-btn',{staticClass:"text-capitalize white--text mr-2",attrs:{"color":"blue","small":"","loading":_vm.parentIsProcessing},on:{"click":function($event){return _vm.executeAction(
                          _vm.allConstants.claims.currentEventActions.fastSolution
                            .acceptCompromise,
                          document.id
                        )}}},[_vm._v("Aceptar")]),_c('v-btn',{staticClass:"text-capitalize white--text",attrs:{"color":"blue","small":"","loading":_vm.parentIsProcessing},on:{"click":function($event){return _vm.executeAction(
                          _vm.allConstants.claims.currentEventActions.fastSolution
                            .RejectCompromise,
                          document.id
                        )}}},[_vm._v("Rechazar")])],1):_vm._e()])])}),0)])])])])]),_c('v-dialog',{attrs:{"width":"900px"},on:{"click:outside":_vm.hideVirtualDocument},model:{value:(_vm.virtualDocument.show),callback:function ($$v) {_vm.$set(_vm.virtualDocument, "show", $$v)},expression:"virtualDocument.show"}},[(_vm.virtualDocument.show)?_c('v-card',[(
          _vm.virtualDocument.document.moreInfo.internalCode ==
            _vm.allConstants.claims.posibleActions.rejectByDeep ||
            _vm.virtualDocument.document.moreInfo.internalCode ==
              _vm.allConstants.claims.posibleActions.rejectByDeepDefender
        )?_c('div',{staticClass:"pa-6"},[(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes.inCourt
          )?_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado por incurrir en la causal de exclusión contemplada en el literal a) del artículo 9 del Reglamento de esta Defensoría del Cliente, esto es, “encontrarse en tramitación o hayan sido resueltos en sede judicial o arbitral, o por las autoridades y/o organismos públicos en el ámbito de su competencia”."),_c('br')]):_vm._e(),(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes.outOfBusiness
          )?_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado por incurrir en la causal de exclusión contemplada en el literal b) del artículo 9 del Reglamento de esta Defensoría del Cliente, esto es, corresponder a reclamos que incidan en materias o asuntos que no sean del giro bancario."),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a recurrir con su caso a las instancias judiciales o administrativas pertinentes."),_c('br')]):_vm._e(),(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes
                .requestCompensation
          )?_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado por incurrir en la causal de exclusión contemplada en el literal c) del artículo 9 del Reglamento de esta Defensoría del Cliente, esto es, que el reclamo persiga indemnizaciones por lucro cesante, daño moral, y aquellas derivadas de responsabilidad extracontractual."),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a recurrir con su caso a las instancias judiciales o administrativas pertinentes."),_c('br')]):_vm._e(),(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes.loanGranting
          )?_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado por incurrir en la causal de exclusión contemplada en el literal d) del artículo 9 del Reglamento de esta Defensoría del Cliente, esto es, por tratar cuestiones relacionadas al otorgamiento de créditos de cualquier naturaleza, concesión de prórrogas o reprogramaciones, aperturas de cuentas corrientes, de ahorro o vista y en general cualquier materia relativa a la facultad discrecional de los bancos para convenir libremente los términos de las operaciones y servicios bancarios."),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a recurrir con su caso a las instancias judiciales o administrativas pertinentes."),_c('br')]):_vm._e(),(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes
                .notQuantifiableInMoney
          )?_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado por incurrir en la causal de exclusión contemplada en el literal e) del artículo 9 del Reglamento de esta Defensoría del Cliente, esto es, por tratar cuestiones no susceptibles de ser cuantificadas en dinero."),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a recurrir con su caso a las instancias judiciales o administrativas pertinentes."),_c('br')]):_vm._e(),(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes.outOfMoneyLimit
          )?_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado, ya que de conformidad al Reglamento de la Defensoría del Cliente, se conocerán sólo aquellos reclamos que al momento de ser analizados o revisados que no sean superiores a 600 unidades de fomento, independiente del tipo o clase de operación bancaria."),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a recurrir con su caso a las instancias judiciales o administrativas pertinentes."),_c('br')]):_vm._e(),(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes.appeal
          )?_c('RejectionLetter',[_vm._v(" Según Reglamento de esta Defensoría del Cliente, no es posible dar curso a su solicitud en razón, que ya fue revisada y finalizada anteriormente."),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a recurrir con su caso a las instancias judiciales o administrativas pertinentes."),_c('br')]):_vm._e(),(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes.extemporaneous
          )?_c('RejectionLetter',[_vm._v(" Según Reglamento de esta Defensoría del Cliente, no es posible dar curso a su solicitud, dado que ha sido presentado fuera del plazo establecido, esto es, seis meses contados desde la fecha de recepción de una respuesta desfavorable, o luego de 6 meses transcurridos 30 días después de la presentación del reclamo sin que el banco hubiera dado respuesta al mismo."),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a recurrir con su caso a las instancias judiciales o administrativas pertinentes."),_c('br')]):_vm._e(),(
            _vm.virtualDocument.document.moreInfo.internalReasonCode ==
              _vm.allConstants.claims.rejectionByDeepReasonsCodes.crime
          )?_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado por incurrir en la causal de exclusión contemplada en el literal g) del artículo 8 del Reglamento de esta Defensoría del Cliente, esto es, por tratar sobre hechos que pudieren revestir el carácter de delito."),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a recurrir con su caso a las instancias judiciales o administrativas pertinentes."),_c('br')]):_vm._e()],1):_vm._e(),(
          _vm.virtualDocument.document.moreInfo.internalCode ==
            _vm.allConstants.claims.posibleActions.rejectByForm
        )?_c('div',{staticClass:"pa-6"},[_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado por no presentar la siguiente información: "),_c('ul',_vm._l((_vm.virtualDocument.document.moreInfo
                .internalDocuments),function(documentName){return _c('li',{key:documentName},[_vm._v(" "+_vm._s(documentName.name)+" ")])}),0),_vm._v(" En caso de no entregar la información solicitada dentro de los próximos 3 días hábiles se cerrará su reclamo. Ello no impedirá que a futuro pueda ingresar un nuevo reclamo, una vez que cuente con todos los antecedentes requeridos. "),_c('br')])],1):_vm._e(),(
          _vm.virtualDocument.document.moreInfo.internalCode ==
            _vm.allConstants.claims.posibleActions.expireRegularize
        )?_c('div',{staticClass:"pa-6"},[_c('RejectionLetter',[_vm._v(" En relación con su Reclamo Folio N° "+_vm._s(this.currentClaimSelected.claim.folio)+", le informamos que éste no ha podido ser ingresado, ya que no se han incorporado los antecedentes suficientes de acuerdo a lo establecido en el Reglamento. "),_c('br'),_vm._v(" Sin perjuicio de lo anterior, hacemos presente que esta resolución de ninguna forma afecta su derecho a presentar nuevamente el reclamo una vez que cuente con todos los antecedentes. ")])],1):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"g-bg-cyan g-color-white"},[_c('th',[_vm._v("Nombre documento")]),_c('th',[_vm._v("Documento")]),_c('th',[_vm._v("Fecha")]),_c('th'),_c('th',[_vm._v("Acciones")])])])}]

export { render, staticRenderFns }