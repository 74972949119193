<template>
    <div class="text-body-1">
        <div class="font-weight-medium mb-5">
            <span class="d-block">Señor</span>    
            <span class="d-block">{{currentClaimSelected.account.userType == allConstants.security.userTypes.person ? currentClaimSelected.account.name + ' ' + currentClaimSelected.account.surname : currentClaimSelected.account.name}}</span>
            <span class="d-block">Presente</span>
        </div>
        <span class="d-block mb-5">Estimado/a Señor/a:</span> 
        <slot></slot>
        <div class="font-weight-medium mt-5 mb-5">Atentamente,</div>
        <div class="font-weight-medium text-right">Defensoría del Cliente</div>
    </div>
</template>

<script>
    import baseViewMixin from "@/mixins/baseView";
    import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
    export default {
        mixins: [baseViewMixin,currentClaimSelectedMixin]
    }
</script>

<style lang="scss" scoped>

</style>