<template>
    <section class="container-fluid g-px-60--lg g-px-50--sm g-pt-10" v-if="currentClaimSelected.lastHumanEvent">
        <div class="media-body u-shadow-v22 g-bg-light-green g-pa-30 g-rounded-15">
            <v-row>
                <v-col cols="12" md="4">
                    <h6 class=""><img src="assets/img/name.svg"> {{getRoleName(currentClaimSelected.lastHumanEvent.rol)}}</h6>
                    <p v-if="currentClaimSelected.lastHumanEvent.rol == 'BankAdministrator'">Banco</p>
                    <p v-else-if="currentClaimSelected.lastHumanEvent.rol == 'BankExecutive'" >Banco</p>
                    <p v-else>{{currentClaimSelected.lastHumanEvent.userName}}</p>
                </v-col>
                <v-col cols="12" md="4">
                    <p class="h6"><img src="assets/img/calendar.svg"> Fecha ingreso</p>
                    <p>{{currentClaimSelected.lastHumanEvent.creationDate | formatDateShort}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="h6">Comentarios</p>
                    <p>{{currentClaimSelected.lastHumanEvent.comments}}</p>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<script>
    import baseViewMixin from "@/mixins/baseView";
    import parametersMixin from "@/mixins/parameters";
    import currentUserMixin from "@/mixins/currentUser";
    import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

    export default {
        mixins: [baseViewMixin, parametersMixin, currentUserMixin, currentClaimSelectedMixin]
    }
</script>

<style lang="scss" scoped>

</style>