<template>
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary lighten-2"
          text
          v-bind="attrs"
          v-on="on"
        >
          Agregar documentos
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 text-white primary lighten-2">
          Agregar documentos
        </v-card-title>

        <v-form v-model="form.isValid" @submit.prevent="enter">
            <v-card-text>
                <br/>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-file-input 
                            label="Documento adicional 1"
                            v-model="document1"
                            :accept="allowedRequiredFileTypes.join(',')" 
                            truncate-length="20"
                            :clearable="true" 
                            :rules="documentosRequeridosRules"
                            :disabled="form.processing"
                            outlined
                            dense>
                            </v-file-input>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-file-input 
                            label="Documento adicional 2"
                            v-model="document2"
                            :accept="allowedRequiredFileTypes.join(',')" 
                            truncate-length="20"
                            :clearable="true" 
                            :rules="documentosRules"
                            :disabled="form.processing"
                            outlined
                            dense>
                            </v-file-input>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-file-input 
                            label="Documento adicional 3"
                            v-model="document3"
                            :accept="allowedRequiredFileTypes.join(',')" 
                            truncate-length="20"
                            :clearable="true" 
                            :rules="documentosRules"
                            :disabled="form.processing"
                            outlined
                            dense>
                            </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                color="primary"
                text
                @click="dialog = false"
                :disabled="form.processing"
            >
                Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                type="submit"
                :disabled="!form.isValid" :loading="form.processing"
            >
                Subir documentos
            </v-btn>
            </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import attachmentsService from "@/services/attachments";
import claimsService from "@/services/claims";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

    export default {
        mixins: [baseViewMixin, currentClaimSelectedMixin],
        data() {
            return {
                document1: null,
                document2: null,
                document3: null,
                dialog: false,
                documentProcess: 0,
                documentFailed: 0,
                documentosRequeridosRules: [
                        value => !!value || "Requerido",
                        value => this.isValidFileName(value) || "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
                        value => this.isValidRequiredFileType(value) || "Formato incorrecto",
                        value => this.isValidFileSize(value) || "Tamaño máximo 10 Mb"
                    ],
                documentosRules: [
                        value => this.isValidFileName(value) || "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
                        value => this.isValidRequiredFileType(value) || "Formato incorrecto",
                        value => this.isValidFileSize(value) || "Tamaño máximo 10 Mb"
                    ],
                allowedRequiredFileTypes: [
                    "application/pdf", 
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-powerpoint",
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "image/gif", 
                    "image/jpeg", 
                    "image/tiff", 
                    "image/svg+xml",
                    "image/png"
                ]
            }
        },
        methods: {
            isValidFileName(fileInfo) {
                if (fileInfo == null)
                    return true;

                return this.allConstants.regex.regexFileNameComplex.test(fileInfo.name);
            },
            isValidFileSize(fileInfo) {
                if (fileInfo == null)
                    return true;

                return fileInfo.size <= 10485760; //10 mg segun requerimiento como tamaño maximo
            },
            isValidRequiredFileType(fileInfo) {
                if (fileInfo == null)
                    return true;

                return (this.allowedRequiredFileTypes.filter(fileType => fileType == fileInfo.type).length > 0)
            },
            async enter() {
                this.initSignalProcessing();
                this.documentProcess = 0;
                this.documentFailed = 0;

                let documents = [];

                documents.push({
                    blobId: await this.uploadOneDocumentV2(this.document1),
                    name: "Documento adicional",
                    fileName: this.document1.name,
                    contentLenght: this.document1.size.toString(),
                    contentType: this.document1.type
                });

                if (this.document2 != null) {
                    documents.push({
                        blobId: await this.uploadOneDocumentV2(this.document2),
                        name: "Documento adicional",
                        fileName: this.document2.name,
                        contentLenght: this.document2.size.toString(),
                        contentType: this.document2.type
                    });
                }

                if (this.document3 != null) {
                    documents.push({
                        blobId: await this.uploadOneDocumentV2(this.document3),
                        name: "Documento adicional",
                        fileName: this.document3.name,
                        contentLenght: this.document3.size.toString(),
                        contentType: this.document3.type
                    });
                }

                if (this.documentFailed > 0) {
                    this.$store.dispatch("notifications/addError", "Error al cargar los archivos");
                    this.stopSignalProcessing();
                    return;
                }

                const data = {
                    documents: documents
                };
                const result = await claimsService.addDocuments(this.currentClaimSelected.claim.id, data);

                if (result.status != 200) {
                    this.$store.dispatch("notifications/addError", "Error al cargar los archivos");
                    this.stopSignalProcessing();
                    return;
                }

                this.dialog = false;
                this.stopSignalProcessing();

                this.$store.dispatch("notifications/addInfo", "Archivos cargados exitosamente");
                this.$emit("fileUploadFinish");
            },
            async uploadOneDocumentV2(blob) {
                const addResult = await attachmentsService.add(blob);
                if (addResult.status != 200) {
                    this.documentFailed++;
                    return "00000000-0000-0000-0000-000000000000";
                }
                
                this.documentProcess++;
                return addResult.data.blobId;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>