<template>
  <v-dialog v-model="showDialog" max-width="600" persistent>
    <v-form v-model="form.isValid" @submit.prevent="enter">
      <v-card>
        <div class="text-right">
          <v-btn icon @click="abort" :disabled="form.processing"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div class="pa-6 pt-1" v-if="documentToReplace">
          <div class="headline text-center mb-4">
            Reemplazar archivo "{{
              documentToReplace ? documentToReplace.name : ""
            }}"
          </div>

          <v-file-input
            v-model="formFields.newBlob"
            :accept="
              documentToReplace.required
                ? allowedRequiredFileTypes.join(',')
                : allowedAlternativesFileTypes.join(',')
            "
            truncate-length="20"
            :clearable="true"
            :rules="
              documentToReplace.required
                ? formFields.documentRequiredRules
                : formFields.documentAlternativeRules
            "
            :disabled="form.processing"
          >
          </v-file-input>
        </div>
        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-btn
            class="btn-main"
            :disabled="!form.isValid"
            :loading="form.processing"
            @click="enter"
            >Subir</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

import attachmentsService from "@/services/attachments";
import claimsService from "@/services/claims";

export default {
  mixins: [baseViewMixin, currentClaimSelectedMixin],
  props: {
    idDocumentToReplace: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      formFields: {
        newBlob: null,
        documentRequiredRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) =>
            this.isValidRequiredFileType(value) || "Formato incorrecto",
        ],
        documentAlternativeRules: [
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) =>
            this.isValidAlternativeFileType(value) || "Formato incorrecto",
        ],
      },
      allowedRequiredFileTypes: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "image/gif",
        "image/jpeg",
        "image/tiff",
        "image/svg+xml",
        "image/png",
      ],
      allowedAlternativesFileTypes: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "image/gif",
        "image/jpeg",
        "image/tiff",
        "image/svg+xml",
        "image/png",
      ],
      showDialog: false,
      documentToReplace: null,
      posibleDocuments: [],
    };
  },
  watch: {
    idDocumentToReplace(newValue) {
      if (!newValue || newValue.length == 0) {
        this.showDialog = false;
        return;
      }

      const theDocument = this.currentClaimSelected.claim.documents.find(
        (document) => document.id == this.idDocumentToReplace
      );
      const theDocumentConfiguration = this.posibleDocuments.find(
        (document) => document.typeName == theDocument.name
      );

      this.documentToReplace = theDocument;

      if (
        theDocumentConfiguration != undefined &&
        theDocumentConfiguration != null
      )
        this.documentToReplace.required = theDocumentConfiguration.required;
      else this.documentToReplace.required = true;

      this.showDialog = true;
    },
  },
  methods: {
    isValidFileName(fileInfo) {
      if (fileInfo == null) return true;

      return this.allConstants.regex.regexFileNameComplex.test(fileInfo.name);
    },
    isValidRequiredFileType(fileInfo) {
      if (fileInfo == null) return true;

      return (
        this.allowedRequiredFileTypes.filter(
          (fileType) => fileType == fileInfo.type
        ).length > 0
      );
    },
    isValidAlternativeFileType(fileInfo) {
      if (fileInfo == null) return true;

      return (
        this.allowedAlternativesFileTypes.filter(
          (fileType) => fileType == fileInfo.type
        ).length > 0
      );
    },
    abort() {
      this.$emit("abort");
    },
    async enter() {
      this.initSignalProcessing();

      //subir nuevo archivo
      const addResult = await attachmentsService.add(this.formFields.newBlob);
      const newBlobId = addResult.data.blobId;

      //actualizar información del documento con nuevo archivo
      const newDocumentInfo = {
        blobId: newBlobId,
        fileName: this.formFields.newBlob.name,
        contentLenght: this.formFields.newBlob.size.toString(),
        contentType: this.formFields.newBlob.type,
      };

      await claimsService.changeDocumentInformation(
        this.currentClaimSelected.claim.id,
        this.documentToReplace.id,
        newDocumentInfo
      );

      this.formFields.newBlob = null;
      this.stopSignalProcessing();
      this.$emit("enter");
    },
  },
  async beforeMount() {
    const parameterStages = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStages"
    );
    const documentsRequired = parameterStages.values.find(
      (stage) => stage.code == "INGRESO"
    ).attachments;

    this.posibleDocuments = documentsRequired.map((document) => {
      return {
        typeName: document.name,
        required: document.required,
      };
    });
  },
};
</script>

<style lang="scss" scoped>
.headline {
  color: #199bca !important;
}
</style>
