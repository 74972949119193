<template>
  <div>
    <section class="container-fluid g-px-80--lg g-px-50--sm g-pt-40">
      <div class="row">
        <div class="col-sm-12 col-lg-12 g-mt-30 g-mb-60">
          <h5 class="g-font-weight-700">Documentos incorporados</h5>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr class="g-bg-cyan g-color-white">
                  <th>Nombre documento</th>
                  <th>Documento</th>
                  <th>Fecha</th>
                  <th></th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="document in documents" :key="document.id">
                  <td>
                    <v-progress-linear
                      buffer-value="0"
                      stream
                      color="cyan"
                      v-if="parentIsProcessing"
                    ></v-progress-linear>
                    <router-link
                      to="#"
                      v-on:click.native="downloadDocument(document)"
                      v-else
                    >
                      {{ document.fileName }}
                      <img src="assets/img/cloud.svg" />
                    </router-link>
                  </td>
                  <td>{{ document.name }}</td>
                  <td>{{ document.creationDate | formatDateShort }}</td>
                  <td></td>
                  <td>
                    <div
                      v-if="
                        currentClaimSelected.currentEvent &&
                          currentClaimSelected.currentEvent.stateCode ==
                            'TRAMITACION-SIMPLIFICADA-ACEPTADA' &&
                          document.name == 'Propuesta Banco'
                      "
                    >
                      <v-btn
                        class="text-capitalize white--text mr-2"
                        color="blue"
                        small
                        :loading="parentIsProcessing"
                        @click="
                          executeAction(
                            allConstants.claims.currentEventActions.fastSolution
                              .claimantAccept,
                            document.id
                          )
                        "
                        >Aceptar</v-btn
                      >
                      <v-btn
                        class="text-capitalize white--text"
                        color="blue"
                        small
                        :loading="parentIsProcessing"
                        @click="
                          executeAction(
                            allConstants.claims.currentEventActions.fastSolution
                              .claimantReject,
                            document.id
                          )
                        "
                        >Rechazar</v-btn
                      >
                    </div>
                    <div
                      v-if="
                        currentClaimSelected.currentEvent &&
                          currentClaimSelected.currentEvent.stateCode ==
                            'AVENIMIENTO-PENDIENTE-CLIENTE' &&
                          document.name == 'Avenimiento'
                      "
                    >
                      <v-btn
                        class="text-capitalize white--text mr-2"
                        color="blue"
                        small
                        :loading="parentIsProcessing"
                        @click="
                          executeAction(
                            allConstants.claims.currentEventActions.fastSolution
                              .acceptCompromise,
                            document.id
                          )
                        "
                        >Aceptar</v-btn
                      >
                      <v-btn
                        class="text-capitalize white--text"
                        color="blue"
                        small
                        :loading="parentIsProcessing"
                        @click="
                          executeAction(
                            allConstants.claims.currentEventActions.fastSolution
                              .RejectCompromise,
                            document.id
                          )
                        "
                        >Rechazar</v-btn
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <v-dialog
      v-model="virtualDocument.show"
      width="900px"
      @click:outside="hideVirtualDocument"
    >
      <v-card v-if="virtualDocument.show">
        <div
          class="pa-6"
          v-if="
            virtualDocument.document.moreInfo.internalCode ==
              allConstants.claims.posibleActions.rejectByDeep ||
              virtualDocument.document.moreInfo.internalCode ==
                allConstants.claims.posibleActions.rejectByDeepDefender
          "
        >
          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes.inCourt
            "
          >
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado por incurrir en la causal de exclusión
            contemplada en el literal a) del artículo 9 del Reglamento de esta
            Defensoría del Cliente, esto es, “encontrarse en tramitación o hayan
            sido resueltos en sede judicial o arbitral, o por las autoridades
            y/o organismos públicos en el ámbito de su competencia”.<br />
          </RejectionLetter>

          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes.outOfBusiness
            "
          >
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado por incurrir en la causal de exclusión
            contemplada en el literal b) del artículo 9 del Reglamento de esta
            Defensoría del Cliente, esto es, corresponder a reclamos que incidan
            en materias o asuntos que no sean del giro bancario.<br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a recurrir con su caso a las
            instancias judiciales o administrativas pertinentes.<br />
          </RejectionLetter>

          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes
                  .requestCompensation
            "
          >
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado por incurrir en la causal de exclusión
            contemplada en el literal c) del artículo 9 del Reglamento de esta
            Defensoría del Cliente, esto es, que el reclamo persiga
            indemnizaciones por lucro cesante, daño moral, y aquellas derivadas
            de responsabilidad extracontractual.<br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a recurrir con su caso a las
            instancias judiciales o administrativas pertinentes.<br />
          </RejectionLetter>

          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes.loanGranting
            "
          >
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado por incurrir en la causal de exclusión
            contemplada en el literal d) del artículo 9 del Reglamento de esta
            Defensoría del Cliente, esto es, por tratar cuestiones relacionadas
            al otorgamiento de créditos de cualquier naturaleza, concesión de
            prórrogas o reprogramaciones, aperturas de cuentas corrientes, de
            ahorro o vista y en general cualquier materia relativa a la facultad
            discrecional de los bancos para convenir libremente los términos de
            las operaciones y servicios bancarios.<br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a recurrir con su caso a las
            instancias judiciales o administrativas pertinentes.<br />
          </RejectionLetter>

          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes
                  .notQuantifiableInMoney
            "
          >
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado por incurrir en la causal de exclusión
            contemplada en el literal e) del artículo 9 del Reglamento de esta
            Defensoría del Cliente, esto es, por tratar cuestiones no
            susceptibles de ser cuantificadas en dinero.<br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a recurrir con su caso a las
            instancias judiciales o administrativas pertinentes.<br />
          </RejectionLetter>

          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes.outOfMoneyLimit
            "
          >
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado, ya que de conformidad al Reglamento de
            la Defensoría del Cliente, se conocerán sólo aquellos reclamos que
            al momento de ser analizados o revisados que no sean superiores a
            600 unidades de fomento, independiente del tipo o clase de operación
            bancaria.<br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a recurrir con su caso a las
            instancias judiciales o administrativas pertinentes.<br />
          </RejectionLetter>

          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes.appeal
            "
          >
            Según Reglamento de esta Defensoría del Cliente, no es posible dar
            curso a su solicitud en razón, que ya fue revisada y finalizada
            anteriormente.<br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a recurrir con su caso a las
            instancias judiciales o administrativas pertinentes.<br />
          </RejectionLetter>

          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes.extemporaneous
            "
          >
            Según Reglamento de esta Defensoría del Cliente, no es posible dar
            curso a su solicitud, dado que ha sido presentado fuera del plazo
            establecido, esto es, seis meses contados desde la fecha de
            recepción de una respuesta desfavorable, o luego de 6 meses
            transcurridos 30 días después de la presentación del reclamo sin que
            el banco hubiera dado respuesta al mismo.<br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a recurrir con su caso a las
            instancias judiciales o administrativas pertinentes.<br />
          </RejectionLetter>

          <RejectionLetter
            v-if="
              virtualDocument.document.moreInfo.internalReasonCode ==
                allConstants.claims.rejectionByDeepReasonsCodes.crime
            "
          >
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado por incurrir en la causal de exclusión
            contemplada en el literal g) del artículo 8 del Reglamento de esta
            Defensoría del Cliente, esto es, por tratar sobre hechos que
            pudieren revestir el carácter de delito.<br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a recurrir con su caso a las
            instancias judiciales o administrativas pertinentes.<br />
          </RejectionLetter>
        </div>
        <div
          class="pa-6"
          v-if="
            virtualDocument.document.moreInfo.internalCode ==
              allConstants.claims.posibleActions.rejectByForm
          "
        >
          <RejectionLetter>
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado por no presentar la siguiente
            información:
            <ul>
              <li
                v-for="documentName in virtualDocument.document.moreInfo
                  .internalDocuments"
                :key="documentName"
              >
                {{ documentName.name }}
              </li>
            </ul>
            En caso de no entregar la información solicitada dentro de los
            próximos 3 días hábiles se cerrará su reclamo. Ello no impedirá que
            a futuro pueda ingresar un nuevo reclamo, una vez que cuente con
            todos los antecedentes requeridos.
            <br />
          </RejectionLetter>
        </div>
        <div
          class="pa-6"
          v-if="
            virtualDocument.document.moreInfo.internalCode ==
              allConstants.claims.posibleActions.expireRegularize
          "
        >
          <RejectionLetter>
            En relación con su Reclamo Folio N°
            {{ this.currentClaimSelected.claim.folio }}, le informamos que éste
            no ha podido ser ingresado, ya que no se han incorporado los
            antecedentes suficientes de acuerdo a lo establecido en el
            Reglamento.
            <br />
            Sin perjuicio de lo anterior, hacemos presente que esta resolución
            de ninguna forma afecta su derecho a presentar nuevamente el reclamo
            una vez que cuente con todos los antecedentes.
          </RejectionLetter>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

import RejectionLetter from "@/components/PublicSite/User/Claim/RejectionLetter";

import attachmentsService from "@/services/attachments";

import { DateTime } from "luxon";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
  components: { RejectionLetter },
  props: {
    parentIsProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documents: [],
      virtualDocument: {
        show: false,
        document: null,
      },
    };
  },
  methods: {
    async downloadDocument(theDocument) {
      this.initSignalProcessing();

      if (theDocument.moreInfo.isVirtual) {
        this.showVirtualDocument(theDocument);
      } else {
        try {
          const downlodResult = await attachmentsService.downlod(
            theDocument.blobId,
            "claims"
          );

          const urlCreator = window.URL || window.webkitURL;
          const contentBlob = new Blob([downlodResult.data], {
            type: theDocument.contentType,
          });

          let link = document.createElement("a");
          link.href = urlCreator.createObjectURL(contentBlob);
          link.download = theDocument.fileName;
          link.click();
        } catch {
          this.showInfoNotification(
            "El archivo ya no existe en nuestros registros"
          );
        }
      }

      this.stopSignalProcessing();
    },
    showVirtualDocument(theDocument) {
      this.virtualDocument.document = theDocument;
      this.virtualDocument.show = true;
    },
    hideVirtualDocument() {
      this.virtualDocument.document = null;
      this.virtualDocument.show = false;
    },
    executeAction(actionCode, relatedDocumentId) {
      this.$emit("executeAction", actionCode, relatedDocumentId);
    },
  },
  async beforeMount() {
    //Agregar documentos fisicos (no virtuales). Pero aquellos que no fueron cargados durante la creación del reclamo
    let idsDocumentsUploadedInCreation = this.currentClaimSelected.claim.events.find(
      (event) => event.actionCode == ""
    ).documentIds;
    const idsDocumentsUploadedInOther = this.currentClaimSelected.claim.events.filter(
      (event) =>
        event.actionCode == "NEW-UPLOAD-FILE" && event.rol == "Claimant"
    );
    const idsDocumentsUploadedInCreationRespuesta = this.currentClaimSelected.claim.events.find(
      (event) =>
        event.actionCode == "RESPUESTA-ANTECEDENTES-ADICIONALES-CLIENTE" ||
        event.actionCode ==
          "RESPUESTA-ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE"
    );

    if (idsDocumentsUploadedInOther.length > 0) {
      idsDocumentsUploadedInOther.forEach((event) => {
        event.documentIds.forEach((d) => {
          if (idsDocumentsUploadedInCreation.find((i) => i == d) == null)
            idsDocumentsUploadedInCreation.push(d);
        });
      });
    }

    if (idsDocumentsUploadedInCreationRespuesta != undefined) {
      this.currentClaimSelected.claim.documents
        .filter((document) =>
          idsDocumentsUploadedInCreationRespuesta.documentIds.includes(
            document.id
          )
        )
        .forEach((doc) => {
          idsDocumentsUploadedInCreation.push(doc.id);
        });
    }

    this.currentClaimSelected.claim.documents
      .filter(
        (document) =>
          !document.virtual &&
          !idsDocumentsUploadedInCreation.includes(document.id)
      )
      .forEach((document) => {
        if (document.name != "Documento adicional") {
          this.documents.push({
            id: document.id,
            blobId: document.blobId,
            contentLenght: document.contentLenght,
            contentType: document.contentType,
            creationDate: document.creationDate,
            fileName: document.fileName,
            name: document.name,
            status: "GENERADO",
            moreInfo: {
              isVirtual: false,
              internalCode: document.metadata.relatedAction ?? "",
              internalReasonCode: document.metadata.relatedReasonCode ?? "",
              internalDocuments: document.metadata.relatedDocuments ?? "",
            },
          });
        }
      });

    //Agregar documentos virtuales explicitamente creados como virtuales
    this.currentClaimSelected.claim.documents
      .filter((document) => document.virtual)
      .forEach((document) => {
        this.documents.push({
          id: this.$uuid.v4(),
          blobId: document.blobId,
          contentLenght: document.contentLenght,
          contentType: document.contentType,
          creationDate: document.creationDate,
          fileName: document.fileName,
          name: document.name,
          status: "GENERADO",
          moreInfo: {
            isVirtual: true,
            internalCode: document.metadata.relatedAction,
            internalReasonCode: document.metadata.relatedReasonCode,
            internalDocuments: document.metadata.relatedDocuments,
          },
        });
      });

    //Agregar documentos virtuales que no estan creados de forma explicita
    const expireRegularizeEvent = this.getEvent(
      this.allConstants.claims.posibleActions.expireRegularize
    );
    if (expireRegularizeEvent) {
      this.documents.push({
        id: this.$uuid.v4(),
        blobId: this.$uuid.v4(),
        contentLenght: 0,
        contentType: "",
        creationDate: expireRegularizeEvent.creationDate,
        fileName: "Carta de vencimiento plazo reg.",
        name: "Carta de vencimiento plazo reg.",
        status: "GENERADO",
        moreInfo: {
          isVirtual: true,
          internalCode: this.allConstants.claims.posibleActions
            .expireRegularize,
          internalReasonCode: "",
        },
      });
    }

    //Order de forma descentender por fecha de creación
    console.log("documents---", this.documents);

    this.documents = this.documents.sort((itemA, itemB) => {
      if (
        DateTime.fromISO(itemA.creationDate) <
        DateTime.fromISO(itemB.creationDate)
      ) {
        return 1;
      }
      if (
        DateTime.fromISO(itemA.creationDate) >
        DateTime.fromISO(itemB.creationDate)
      ) {
        return -1;
      }
      return 0;
    });
  },
};
</script>

<style lang="scss" scoped></style>
